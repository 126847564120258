import React from 'react'

const ThankYou = () => {
    return (
        <div>
            Thank You
        </div>
    )
}

export default ThankYou
